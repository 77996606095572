/**
 * RedirectSkipper | app style
 */

html,
body,
.container {
    height: 100%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.redirect-view {
    width: 100%;
    max-width: 450px;

    padding: 12px 0;
    margin-top: auto;
    margin-bottom: auto;
}
